.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    // width: 70%;
    border-radius: 10px;
    left: 35%;
    right: 35%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow: auto;
}