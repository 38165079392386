.agent-creation{
    padding: 40px 135px;
}

.info-div{
   margin-top: 30px; 
}

.agent-sign{
  color: #000000;
  font-weight: 700;  
  font-size: 22px !important;
  line-height: 50px
}


.agent-info{
    font-size: 14px;
    color: #4F4F4F;
}

.refer{
    text-align: center !important;
    margin-top: 20px;
}
.bvn-error{
    font-size: 14px;
    color: #FF0015;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

.agent-warning{
    font-size: 12px;
    color: #828282;
}

form label{
    font-size: 16px;
    // color: #bdbdbd;
    color: #4F4F4F;
    margin-bottom: 4px;
}

.left-five{
    margin-left: 5px;
}

.left-ten{
    margin-left: 10px;
}

.mb-05{
    margin-bottom: 5px;
}

.nopadding{
    padding: 0;
}

.up-thirty{
    margin-top: 30px !important;
}

.right-five{
    margin-right: 5px;
}

.right-twenty{
    margin-right: 20px;
}

.left-right{
    left: 25% !important;
    right: 25% !important;
    top: 10% !important;
    bottom: 10% !important;
}

.full-width{
    width: 100% !important;
}

.form-back{
    background: #C4C4C4;
    border-radius: 6px;
    color: #000000;
    border: none;
    border-color: transparent; 
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.03em;
}

.form-back:hover{
    cursor: pointer;
}

.transparent{
    // background-color: #ff001505;
    background: rgba(255, 0, 21, 0.02);
    border-radius: 6px;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.03em;
    box-sizing: border-box;
}

.transparent:focus{
    outline: none;
}

.form-continue:focus{
    outline: none;
}

.form-back:focus{
    outline: none;
}

.form-continue{
    background: #FF0015;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    border-color: transparent; 
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    // font-weight: 600;
    letter-spacing: 0.03em;
}

.form-continue:hover{
    cursor: pointer;
}

.form-continue[disabled]{
    cursor: not-allowed;
    opacity: 0.3;
}

button[disabled] {
    cursor: not-allowed;
}

.inquiry{
    font-size: 16px;
    line-height: 25px;
    color: #4F4F4F;
}

.inquiry-div{
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.sub-head{
    margin-top: 30px;
    margin-bottom: 60px;
}
.agent-verify{
    font-weight: 600;
    color: #4F4F4F;
    font-size: 18px !important;
    line-height: 25px
}

.agent-know{
    font-weight: 600;
    color: #4F4F4F;
    font-size: 16px !important;
    line-height: 25px
}

.agent-phone{
    font-weight: 500;
    color: #4F4F4F;
    font-size: 16px !important;
    line-height: 25px;
    text-align: center;
}

.resend{
    font-size: 14px;
    color: #4F4F4F;
}

.user-icon{
    margin-top: -7px;
    margin-right: 5px;
}

.cursor-pointer{
    cursor: pointer;
}

.add-div{
    background-color: #F2F2F2;
    border-radius: 50px;
    width: 54px;
    height: 54px;
    text-align: center;
}

.add-icon{
    margin-top: 14px
}

.profile-password-icon{
    // position: absolute;
    // top: 10px;
    // cursor: pointer;
    // right:11px;

    margin-top: 10px;
    margin-left: -30px;
    z-index: 500;
}

.bvn-input{
    padding: 27px 40px;
}

.bvn-actions{
    padding: 30px;
    text-align: center;
}

// .green{
//     height: 24px;
//     width: 24px;
//     border-radius: 50px;
//     background-color: #27AE60 !important;
// }

.document-upload{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.display-block{
    display: block !important;
}

.img-div{
    height: 56px;
    width: 56px;
    border-radius: 50px;
}

// .d-flex {
//     display: -webkit-box !important;
//     display: -ms-flexbox !important;
//     display: flex !important;
// }
  
// .align-items-center {
//     -webkit-box-align: center !important;
//     -ms-flex-align: center !important;
//     align-items: center !important;
// }

.ml-04 {
    margin-left: 0.8rem !important;
}

.mt-15{
    margin-top: 15px;
}

.permission{
    color: #333333;
    font-size: 15px !important;
    line-height: 18px;
}

.checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0;
}

.checkbox > input:checked ~ span {
    background-color: #FF0015;
}

.checkbox > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: none;
}

.checkbox.checkbox-lg > span {
    height: 30px;
    width: 30px;
}

.checkbox > span {
    background-color: #EBEDF3;
    border: 1px solid transparent;
}

.checkbox > span {
    height: 18px;
    width: 18px;
}

.checkbox > span {
    background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0.42rem;
}
  
.checkbox > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
  
.checkbox > input:checked ~ span:after {
    border-color: #ffffff;
}
  
.checkbox > input:checked ~ span:after {
    display: block;
}

.checkbox.checkbox-lg > span:after {
    width: 6px;
    height: 16px;
}

.checkbox > span:after {
    width: 5px;
    height: 10px;
}

.checkbox > span:after {
    content: '';
    border-color: transparent;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(
  45deg
  )/*rtl:ignore*/;
    transform: rotate(
  45deg
  )/*rtl:ignore*/;
    margin-top: -2px;
}

.checkbox-round{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0; 
}

.checkbox-round > input:checked ~ span {
    background-color: #F6B333;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: none;
    width: 24px;
    height: 24px;
    border: 3px solid #EBEDF3;
}

.checkbox-round > span {
    background-color: #EBEDF3;
    border: 1px solid transparent;
    height: 24px;
    width: 24px;
    // background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50px;
}
 
.checkbox-round > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
  
// .checkbox-round > input:checked ~ span:after {
//     border-color: #ffffff;
//     display: block;
// }

// .checkbox-round > span:after {
//     width: 5px;
//     height: 10px;
//     content: '';
//     border-color: transparent;
//     border-style: solid;
//     border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
//     -webkit-transform: rotate(
//   45deg
//   )/*rtl:ignore*/;
//     transform: rotate(
//   45deg
//   )/*rtl:ignore*/;
//     margin-top: -2px;
// }

.divider{
    opacity: 0.5;
    border-bottom: 1.5px solid #F2F2F2;
}

.content{
    font-size: 14px;
    color: #4F4F4F;
    line-height: 25px;
}