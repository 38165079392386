.loaderContainer{
  height: 100vh;
  width: 100vw;
  background: white;
  position: relative;
  background-size: 400% 400%;
  -webkit-animation: Gradient 3s ease infinite;
  -moz-animation: Gradient 3s ease infinite;
  animation: Gradient 3s ease infinite;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #000;
  letter-spacing: 0.2em;
}
.loader::before, .loader::after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  background: #ce4233;
  position: absolute;
  -webkit-animation: load .7s infinite alternate ease-in-out;
          animation: load .7s infinite alternate ease-in-out;
}
.loader::before {
  top: 0;
}
.loader::after {
  bottom: 0;
}

@-webkit-keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 35px;
  }
  50% {
    height: 8px;
    width: 60px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 35px;
  }
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 35px;
  }
  50% {
    height: 8px;
    width: 60px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 35px;
  }
}

/*.loader{
  background-color: red;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}*/