.login-page-header{
    margin-bottom: 40px;
    img{
        width: 150px; 
        height: 55px;
    }
    p {
        font-size: 16px;
        color: #4d4f5c;
        font-weight: bolder;
    }
}