.accordion-div{
    margin-top: 20px
}

.accordion-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-bvn{
    font-weight: 500;
    color: #4F4F4F;
    font-size: 16px !important;
    line-height: 25px;
}

.accordion-content{
    margin-top: 5px;
}