/* Start of CSS Loader isLogging.js*/

.lds-ellipsis { display: inline-block; position: relative; width: 100%; left: 40%; height: 5px; padding: 0; }
.lds-ellipsis div { position: absolute; width: 5px; height: 5px; border-radius: 50%; background: #fff; animation-timing-function: cubic-bezier(0, 1, 1, 0); }
.lds-ellipsis div:nth-child(1) { left: 6px; animation: lds-ellipsis1 0.6s infinite; }
.lds-ellipsis div:nth-child(2) { left: 6px; animation: lds-ellipsis2 0.6s infinite; }
.lds-ellipsis div:nth-child(3) { left: 20px; animation: lds-ellipsis2 0.6s infinite; }
.lds-ellipsis div:nth-child(4) { left: 30px; animation: lds-ellipsis3 0.6s infinite; }
@keyframes lds-ellipsis1 { 0% { transform: scale(0); } 100% { transform: scale(1); } }
@keyframes lds-ellipsis3 { 0% { transform: scale(1); } 100% { transform: scale(0); } }
@keyframes lds-ellipsis2 { 0% { transform: translate(0, 0); } 100% { transform: translate(19px, 0); } }

/* End of CSS Loader isLogging.js*/